// Generated by Framer (1249b65)

import { addFonts, addPropertyControls, cx, CycleVariantState, getFonts, getPropertyControls, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Audio } from "https://framerusercontent.com/modules/NRKVbMFYrBaqL0rx532t/kDypoRR2B1GRROQ0uGdY/Audio.js";
const AudioFonts = getFonts(Audio);
const AudioControls = getPropertyControls(Audio);

const cycleOrder = ["qdvKKjVH4"];

const variantClassNames = {qdvKKjVH4: "framer-v-osvb4p"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, mp3File, width, ...props}) => { return {...props, Ziyn6eWqv: mp3File ?? props.Ziyn6eWqv ?? "https://framerusercontent.com/assets/EQxN5DPIELs71J7A7DPut6RCww.mp3"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;mp3File?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Ziyn6eWqv, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "qdvKKjVH4", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-UMWzv", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-osvb4p", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"qdvKKjVH4"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgb(204, 204, 204)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, filter: "invert(1)", WebkitFilter: "invert(1)", ...style}}><motion.div className={"framer-poi7ul-container"} layoutDependency={layoutDependency} layoutId={"BYIvEysSe-container"}><Audio background={"rgb(235, 235, 235)"} borderRadius={6} bottomLeftRadius={6} bottomRightRadius={6} font={{fontFamily: "\"Inter\", sans-serif", fontSize: "14px"}} gap={16} height={"100%"} id={"BYIvEysSe"} isMixedBorderRadius={false} layoutId={"BYIvEysSe"} loop={false} onPlayGlobalPauseOption={"continue"} padding={16} paddingBottom={16} paddingLeft={16} paddingPerSide={false} paddingRight={16} paddingTop={16} pauseOnExit={false} playing playPauseCursor={"pointer"} progress={0} progressColor={"rgb(0, 0, 0)"} showPlayPause showTime showTrack srcFile={Ziyn6eWqv} srcType={"Upload"} srcUrl={"https://assets.mixkit.co/music/preview/mixkit-tech-house-vibes-130.mp3"} style={{height: "100%", width: "100%"}} topLeftRadius={6} topRightRadius={6} trackColor={"rgb(217, 217, 217)"} volume={100} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-UMWzv [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-UMWzv .framer-1dhcyw6 { display: block; }", ".framer-UMWzv .framer-osvb4p { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 560px; will-change: var(--framer-will-change-override, transform); }", ".framer-UMWzv .framer-poi7ul-container { flex: 1 0 0px; height: 48px; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-UMWzv .framer-osvb4p { gap: 0px; } .framer-UMWzv .framer-osvb4p > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-UMWzv .framer-osvb4p > :first-child { margin-left: 0px; } .framer-UMWzv .framer-osvb4p > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 560
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"Ziyn6eWqv":"mp3File"}
 * @framerImmutableVariables false
 */
const FramerwPHmE7gqX: React.ComponentType<Props> = withCSS(Component, css, "framer-UMWzv") as typeof Component;
export default FramerwPHmE7gqX;

FramerwPHmE7gqX.displayName = "Component/Audio Course Player";

FramerwPHmE7gqX.defaultProps = {height: 48, width: 560};

addPropertyControls(FramerwPHmE7gqX, {Ziyn6eWqv: AudioControls?.["srcFile"] && {...AudioControls["srcFile"], __defaultAssetReference: "data:framer/asset-reference,EQxN5DPIELs71J7A7DPut6RCww.mp3?originalFilename=1+Welcome.mp3", hidden: undefined, title: "mp3 File"}} as any)

addFonts(FramerwPHmE7gqX, [...AudioFonts])